import { Injectable } from '@angular/core';

import {
  AppConfiguration,
  InConfiguration,
  PageLocation,
} from '../models/config.interface';
import { PagesService } from '../../pages/pages.service';
import { Page } from '../../pages/models/page';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  configData: InConfiguration;
  appConfig: AppConfiguration;
  urlRegex = /(\/)+[\w-]\w+(\-[\w-]+)?/;
  configLoaded: Observable<boolean>;
  private configLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private pageLocations: PageLocation[] = [];

  constructor(private pagesService: PagesService) {
    this.configLoaded = this.configLoaded$.asObservable();

    this.pagesService.getPages().subscribe((pages: Page[]) => {
      this.pageLocations = this.createPageLocations(pages);
      this.setAppConfig();
      this.configLoaded$.next(true);
    });
    this.setConfigData();
    this.setAppConfig();
  }

  /**
   * Create page locations. By default all-pages is added. Location's id is created by removing the slash from url,
   * home page being the exception
   *
   * @param pages from which url and title are extracted to create the locations
   * @returns the page locations
   */
  private createPageLocations(pages: Page[]): PageLocation[] {
    let pageLocations: PageLocation[] = [
      { id: 'all-pages', name: 'All Pages' },
    ];

    pages.forEach((page) => {
      pageLocations.push({
        id: page.url !== '/' ? page.url.substring(1, page.url.length) : 'home',
        name: page.title,
      });
    });

    return pageLocations;
  }

  setConfigData() {
    this.configData = {
      layout: {
        rtl: false, // options:  true & false
        variant: 'light', // options:  light & dark
        theme_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        logo_bg_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        sidebar: {
          collapsed: false, // options:  true & false
          backgroundColor: 'light', // options:  light & dark
        },
      },
    };
  }

  setAppConfig() {
    this.appConfig = {
      upload: {
        storyImageDir: 'story-feature-images',
      },
      pageSize: 10,
      pageSizeOptions: [5, 10, 20],
      pageLocations: this.pageLocations,
      templates: [
        {
          id: 'tag1',
          name: 'Template1',
        },
        {
          id: 'template2',
          name: 'Template2',
        },
        {
          id: 'template3',
          name: 'Template3',
        },
      ],
      storyTags: [
        { id: 'case-studies', name: 'Case Study' },
        { id: 'thought-leadership', name: 'Thought Leadership' },
        { id: 'page', name: 'Page' },
        { id: 'testimonials', name: 'Testimonials'},
      ],
      orderFields: [
        {
          id: 'title',
          name: 'Title',
        },
        {
          id: 'published',
          name: 'Published',
        },
        {
          id: 'order',
          name: 'Custom',
        },
      ],
      stories: {
        maxAuthors: 3,
        maxContributors: 3,
      },
      confirmDialogConfig: {
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      },
    };
  }
}
