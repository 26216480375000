import firebase from 'firebase/compat/app';
import { updateDoc } from 'firebase/firestore';
import { Observable, Subscriber } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, DocumentReference } from '@angular/fire/compat/firestore';

import { Page } from './models/page';

@Injectable()
export class PagesService {
  private collectionName = 'pages';
  private collectionRef: firebase.firestore.CollectionReference;

  // @TODO: investigate why we need afs here for firebase methods
  constructor(private readonly afs: AngularFirestore) {
    this.collectionRef = firebase.firestore().collection(this.collectionName);
  }

  getPages(): Observable<Page[]> {
    const query = this.collectionRef.orderBy('title', 'asc');

    return new Observable<Page[]>((subscriber: Subscriber<Page[]>) => {
      query.onSnapshot(snap => {
        const pages = snap.docs.map((doc: firebase.firestore.DocumentData) => ({
          id: doc.id,
          ...doc.data()
        }));
        subscriber.next(pages);
      }, err => {
        console.log(`Encountered error on PagesService::getPages: ${err}`);
      });
    });
  }

  async getPage(id: string): Promise<firebase.firestore.DocumentData> {
    const docRef = this.collectionRef.doc(id);
    const docSnap = await docRef.get();

    if (docSnap.exists) {
      return { id: docSnap.id, ...docSnap.data() };
    }

    console.log(`No document for id: ${id}`);
  }

  add(page: Page): Promise<DocumentReference<DocumentData>> {
    return this.collectionRef.add(page);
  }

  update(id: string, updatedValues: any): Promise<void> {
    const docRef = this.collectionRef.doc(id);
    return updateDoc(docRef, updatedValues);
  }

  delete(id: string): Promise<void> {
    const docRef = this.collectionRef.doc(id);
    return docRef.delete();
    // return deleteDoc(docRef); // @TODO: investigate why deleteDoc gives an error
  }
}
