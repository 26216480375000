import { PageLocation } from 'src/app/core/models/config.interface';
import { ConfigService } from 'src/app/core/service/config.service';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pageLocations' })
export class PageLocationsPipe implements PipeTransform {
  private pageLocations: PageLocation[];

  constructor(private config: ConfigService) {
    this.pageLocations = this.config.appConfig.pageLocations;
  }

  transform(location: string[]): string {
    return location
      .map((loc: string) => {
        const locationConfig = this.pageLocations.find((l: PageLocation) => l.id === loc);

        if (locationConfig) {
          return locationConfig.name;
        }
      })
      .filter(v => v !== undefined)
      .join(', ');
  }
}

