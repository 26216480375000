import firebase from 'firebase/compat/app';
import { doc, updateDoc } from 'firebase/firestore';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { StoriesOrderSettings } from 'src/app/settings/models/stories-order-settings';
import { Story } from 'src/app/stories/models/story.model';

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { OrderByDirection, Timestamp } from '@angular/fire/firestore';

import { SettingsService } from './settings.service';

@Injectable()
export class StoriesService {
  private collectionName = 'stories';
  private storiesCount: number;
  private stories: Story[];
  private stories$: Observable<Story[]>;
  private storiesCollection: AngularFirestoreCollection;
  private locationFilter$: BehaviorSubject<string | null>;
  private orderBy$: BehaviorSubject<{
    field: string;
    direction: OrderByDirection;
  } | null>;

  constructor(
    private readonly afs: AngularFirestore,
    private settingsService: SettingsService
  ) {
    this.locationFilter$ = new BehaviorSubject(null);
    this.orderBy$ = new BehaviorSubject(null);

    this.storiesCollection = this.afs.collection<Story>(this.collectionName);

    this.stories$ = combineLatest([this.locationFilter$, this.orderBy$]).pipe(
      switchMap(([location, orderBy]) =>
        this.afs
          .collection<Story>(this.collectionName, (ref) => {
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;

            if (orderBy) {
              query = query.orderBy(orderBy.field, orderBy.direction);
            }

            if (location) {
              query = query.where('location', 'array-contains', location);
            }

            return query;
          })
          .valueChanges({ idField: 'id' })
      )
    );

    this.settingsService
      .getStoriesOrderSettings()
      .subscribe((storiesOrderSetting: StoriesOrderSettings) =>
        this.orderBy(storiesOrderSetting.field, storiesOrderSetting.direction)
      );
  }

  filterByLocation(location: string): void {
    this.locationFilter$.next(location);
  }

  orderBy(field: string, direction: OrderByDirection): void {
    this.orderBy$.next({ field, direction });
  }

  // @TODO: get stories count in an effective way
  getStories(): Observable<Story[]> {
    return this.stories$.pipe(
      tap((stories: Story[]) => {
        this.storiesCount = stories.length;
      })
    );
  }

  getStoryDoc(id: string): AngularFirestoreDocument<Story> {
    return this.afs.doc<Story>(`stories/${id}`);
  }

  getStory(id: string): Observable<Story> {
    const doc: AngularFirestoreDocument<Story> = this.getStoryDoc(id);

    return doc.valueChanges({ idField: 'id' });
  }

  addStory(story: Story): Promise<DocumentReference<Story>> {
    return this.afs.collection<Story>(this.collectionName).add({
      ...story,
      order: this.storiesCount || 0,
      published: Timestamp.now(),
    });
  }

  async deleteStory(story: Story): Promise<void> {
    let removedIndex = story.order;
    const batch = firebase.firestore().batch();
    const storyRef = firebase
      .firestore()
      .collection(this.collectionName)
      .doc(story.id);
    const storySnapshot = await storyRef.get();

    const storiesToUpdateSnapshot = firebase
      .firestore()
      .collection(this.collectionName)
      .orderBy('order')
      .startAfter(storySnapshot);

    const storiesToUpdate = await storiesToUpdateSnapshot.get();
    storiesToUpdate.forEach((storyToUpdate) => {
      batch.update(storyToUpdate.ref, { order: removedIndex++ });
    });

    await batch.commit();

    return storyRef.delete();
  }

  async reorderStories(stories: Story[]): Promise<void> {
    const batch: firebase.firestore.WriteBatch = firebase.firestore().batch();

    stories.forEach((story: Story) => {
      const storyRef = firebase
        .firestore()
        .collection(this.collectionName)
        .doc(story.id);
      batch.update(storyRef, { order: story.order });
    });

    await batch.commit();
  }

  // ******************************
  // Firebase native methods below
  // ******************************

  async _getStoriesUpdates() {
    const query = firebase.firestore().collection(this.collectionName);

    const observer = query.onSnapshot(
      (querySnapshot) => {
        console.log(
          `Received query snapshot of size ${querySnapshot.size}`,
          querySnapshot
        );
        // ...
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );

    // return observer;
  }

  async _getStories(): Promise<Story[]> {
    const storiesRef = firebase.firestore().collection(this.collectionName);
    const storiesSnap = await storiesRef.get();

    return storiesSnap.docs.map(
      (document: firebase.firestore.QueryDocumentSnapshot<Story>) => ({
        id: document.id,
        ...document.data(),
      })
    );
  }

  async _getStory(id: string): Promise<firebase.firestore.DocumentData> {
    const storyRef = firebase
      .firestore()
      .collection(this.collectionName)
      .doc(id);
    const storySnap = await storyRef.get();

    if (!storySnap.exists) {
      console.log('No such document!');
      return;
    } else {
      console.log('Document data: ', storySnap.data());
    }

    return storySnap.data();
  }

  _addStory(story: Story): Promise<any> {
    return firebase
      .firestore()
      .collection(this.collectionName)
      .add({
        ...story,
        order: this.storiesCount,
        published: Timestamp.now(),
      });
  }

  _updateStory(id: string, updatedValues: any): Promise<void> {
    const storyRef = firebase
      .firestore()
      .collection(this.collectionName)
      .doc(id);
    return updateDoc(storyRef, updatedValues);
  }

  checkUniqueURL(url: string): boolean {
    this.getStories().subscribe((stories) =>
      stories.forEach((story) => console.log(story))
    );
    return true;
  }

  // private onDocumentRemoved(): void {
  //   this.afs.collection(this.collectionName)
  //     .stateChanges(['removed'])
  //     .pipe(
  //       map((actions: DocumentChangeAction<Story>[]) => actions.map(action => {
  //         const data = action.payload.doc.data() as Story;
  //         const id = action.payload.doc.id;
  //         return { id, ...data };
  //       })),
  //       filter((removedStories: Story[]) => removedStories.length > 0)
  //     )
  //     .subscribe((removedStories: Story[]) => {
  //       console.log('removedStories', removedStories);
  //       removedStories.forEach((removedStory: Story) => {
  //         this.reorderFrom(removedStory);
  //       });
  //     });
  // }

  // private async reorderFrom(removedStory?: Story) {
  //   let removedIndex = removedStory.order;
  //   const batch = firebase.firestore().batch();
  //   const removedStoryRef = firebase.firestore().collection('stories').doc(removedStory.id);
  //   const removedStorySnapshot = await removedStoryRef.get();
  //   const startAtSnapshot = firebase.firestore().collection('stories')
  //     .orderBy('order')
  //     .startAt(removedStorySnapshot);

  //   const stories = await startAtSnapshot.get();
  //   stories.forEach((story) => {
  //     batch.update(story.ref, { order: removedIndex++ });
  //   });

  //   await batch.commit();
  // }
}
