<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="stories">
        <img src="assets/images/logo.png" alt="" />
        <span class="logo-name">Tickbird CMS</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <app-feather-icons [icon]="'maximize'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User">
          </a>
          <div ngbDropdownMenu class="notification-dropdown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
