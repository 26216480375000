import { NgModule } from '@angular/core';
import {
    AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { Page404Component } from './authentication/page404/page404.component';
// import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const redirectLoggedInToStories = () => redirectLoggedInTo(['stories']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['authentication', 'signin']);

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        redirectTo: '/authentication/signin',
        pathMatch: 'full'
      },
      {
        path: 'stories',
        loadChildren: () =>
          import('./stories/stories.module').then((m) => m.StoriesModule)
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule)
      },
      {
        path: 'pages-marketing',
        loadChildren: () =>
          import('./pages-marketing/pages-marketing.module').then((m) => m.PagesMarketingModule)
      },
      {
        path: 'button-events',
        loadChildren: () =>
          import('./button-events/button-events.module').then((m) => m.ButtonEventsModule)
      }
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToStories },
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  {
    path: '**',
    component: Page404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
