import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: 'stories',
    title: 'MENUITEMS.STORIES.TEXT',
    icon: 'command',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'pages',
    title: 'Pages',
    icon: 'align-justify',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'pages-marketing',
    title: 'Marketing Pages',
    icon: 'crosshair',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'button-events',
    title: 'Button Events',
    icon: 'mouse-pointer',
    class: '',
    groupTitle: false,
    submenu: []
  },
  // {
  //   path: 'users',
  //   title: 'Users',
  //   icon: 'user',
  //   class: '',
  //   groupTitle: false,
  //   submenu: []
  // },
  {
    path: 'settings',
    title: 'Settings',
    icon: 'settings',
    class: '',
    groupTitle: false,
    submenu: []
  },
  // {
  //   path: '',
  //   title: 'Authentication',
  //   icon: 'user-check',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu: [
  //     {
  //       path: '/authentication/signin',
  //       title: 'Sign In',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/page404',
  //       title: '404 - Not Found',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/authentication/page500',
  //       title: '500 - Server Error',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // }
];
