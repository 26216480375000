import { Observable } from 'rxjs';
import { StoriesOrderSettings } from 'src/app/settings/models/stories-order-settings';

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { OrderByDirection } from '@angular/fire/firestore';

@Injectable()
export class SettingsService {
  private collectionName = 'settings';
  private storiesOrderDocumentName = 'stories-order';
  private storiesOrderSettings$: Observable<StoriesOrderSettings>;

  constructor(private readonly afs: AngularFirestore) {
    this.storiesOrderSettings$ = this.afs
      .doc<StoriesOrderSettings>(`${this.collectionName}/${this.storiesOrderDocumentName}`)
      .valueChanges();
  }

  getStoriesOrderSettings(): Observable<StoriesOrderSettings> {
    return this.storiesOrderSettings$;
  }

  updateStoriesOrderSettings(field: string, direction: OrderByDirection): Promise<void> {
    const doc: AngularFirestoreDocument<StoriesOrderSettings> = this.afs
      .doc<StoriesOrderSettings>(`${this.collectionName}/${this.storiesOrderDocumentName}`);
    return doc.update({ field, direction });
  }
}
