import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class HtmlSanitizerPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): SafeHtml {
    const sanitizedContent: string = this.sanitizer.sanitize(SecurityContext.HTML, value);
    const safeContent: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);

    return safeContent;
  }
}
