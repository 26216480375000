import { CommonModule } from "@angular/common";
import { NgModule, Optional, SkipSelf } from "@angular/core";

import { throwIfAlreadyLoaded } from "./guard/module-import.guard";
import { AuthService } from "./service/auth.service";
import { DynamicScriptLoaderService } from "./service/dynamic-script-loader.service";
import { RightSidebarService } from "./service/rightsidebar.service";
import { SettingsService } from "./service/settings.service";
import { StoriesService } from "./service/stories.service";
import { PagesService } from "../pages/pages.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    RightSidebarService,
    AuthService,
    DynamicScriptLoaderService,
    StoriesService,
    SettingsService,
    PagesService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
