import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FeatherIconsModule } from './components/feather-icons/feather-icons.module';
import { MaterialModule } from './material.module';
import { HtmlSanitizerPipe } from './pipes/html-sanitizer.pipe';
import { PageLocationsPipe } from './pipes/page-locations.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  declarations: [
    PageLocationsPipe,
    TruncatePipe,
    HtmlSanitizerPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    MaterialModule,
    FeatherIconsModule,
    PageLocationsPipe,
    TruncatePipe,
    HtmlSanitizerPipe
  ]
})
export class SharedModule {}
