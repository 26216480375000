// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'tickbird-crm',
    appId: '1:234261677601:web:18a4f7ee01e51c0c440560',
    storageBucket: 'tickbird-crm.appspot.com',
    locationId: 'europe-west3',
    apiKey: 'AIzaSyA7VaF68NEqvn4PozP6o42UtAy8DszC2uA',
    authDomain: 'tickbird-crm.firebaseapp.com',
    messagingSenderId: '234261677601',
  },
  production: false,
  apiUrl: 'http://localhost:4200'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
