import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<firebase.User>;
  public currentUser: Observable<firebase.User>;

  constructor(private auth: AngularFireAuth) {
    this.currentUserSubject = new BehaviorSubject<firebase.User>(undefined);
    this.currentUser = this.currentUserSubject.asObservable();

    this.auth.onAuthStateChanged(
      (user: firebase.User) => this.currentUserSubject.next(user),
      (error: firebase.auth.Error) => console.log('Auth state changed error: ', error)
    );
  }

  get currentUserValue(): firebase.User {
    return this.currentUserSubject.value;
  }

  get currentUserStatic() {
    return this.auth.currentUser;
  }

  login(username: string, password: string): Promise<void> {
    return this.auth
      .signInWithEmailAndPassword(username, password)
      .then((credential: firebase.auth.UserCredential) => {
        this.currentUserSubject.next(credential.user);
      })
      // .catch(error => console.log('Error during login: ', error));
  }

  logout(): Promise<void> {
    return this.auth
      .signOut()
      .then(_ => this.currentUserSubject.next(null))
      .catch(error => console.log('Error during signout: ', error));
  }
}
